import React from "react";
import VinilGloves from "../img/emin-baycan-irZ5ZzboLkI-unsplash.jpg"
import LatexGloves from "../img/diana-polekhina-sxh2s6hazyE-unsplash.jpg"
import NitrileGloves from "../img/maskmedicare-shop-Nq5XRCXYBNY-unsplash.jpg"
import BataMedico from "../img/bata_medico.png"
import BataPaciente from "../img/bata_paciente.png"
import Oruga from "../img/alexander-grey-O6LZ3j_fK88-unsplash.jpg"
import Polainas from "../img/Polainas.jpg"
import FaceMask from "../img/jeshoots-com-LAwsF-VnFzc-unsplash.jpg"
import Gasas from "../img/Gasas.png"

const Contentbox = () => {
    return(
        <div className="album py-5 bg-body-tertiary">
        <div className="container hover13">
    
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
            <div className="col">
              <div className="card shadow-sm">
                <img src={VinilGloves} className="bd-placeholder-img card-img-top" width="100%" height="225" alt="Guantes de Vinilo" />
                <div className="card-body">
                  <p className="card-text paragraph">Los Guantes de Vinilo se utilizan para tareas de corta duración. Ofrecen mayor permeabilidad y menos resistencia que el látex y el nitrilo </p>
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="fw-normal">Guantes de Vinilo</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card shadow-sm">
                <img src={LatexGloves} className="bd-placeholder-img card-img-top" width="100%" height="225" alt="Guantes de Latex" />
                <div className="card-body">
                  <p className="card-text paragraph">Los guantes de látex, goma o caucho son un tipo de guante fabricado de elastómeros. Su uso se relaciona con elementos que requieren limpieza.</p>
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="fw-normal">Guantes de Latex</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card shadow-sm">
                <img src={NitrileGloves} className="bd-placeholder-img card-img-top" width="100%" height="225" alt="Guantes de Nitrilo" />
                <div className="card-body">
                  <p className="card-text paragraph">El nitrilo es una goma sintética que no tiene ningún contenido de proteína del látex y son el tipo más duradero de guantes desechables.</p>
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="fw-normal">Guantes de Nitrilo</h2>
                  </div>
                </div>
              </div>
            </div>
    
            <div className="col">
              <div className="card shadow-sm">
                <img src={BataMedico} className="bd-placeholder-img card-img-top" width="100%" height="225" alt="Bata Medico"/>
                <div className="card-body">
                  <p className="card-text paragraph">La ropa médica desechable se utiliza en procedimientos quirúrgicos presentes en todas las áreas de la salud.</p>
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="fw-normal">Bata Médico</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card shadow-sm">
                <img src={BataPaciente} className="bd-placeholder-img card-img-top" width="100%" height="225" alt="Bata Paciente"/>
                <div className="card-body">
                  <p className="card-text paragraph">La Bata quirúrgica paciente. También llamada estéril está hecha de tela de algodón de buena calidad, con una abertura posterior y cintas para anudarse.</p>
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="fw-normal">Bata Paciente</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card shadow-sm">
                <img src={Oruga} className="bd-placeholder-img card-img-top" width="100%" height="225" alt="Orugas"/>
                <div className="card-body">
                  <p className="card-text paragraph">Es una prenda de protección y aislamiento para realizar diferentes actividades, como cirugías y limpieza, entre muchas otras. </p>
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="fw-normal">Gorro Tipo Oruga</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card shadow-sm">
                <img src={Polainas} className="bd-placeholder-img card-img-top" width="100%" height="225" alt="Polainas"/>
                <div className="card-body">
                  <p className="card-text paragraph">Es un producto de Bioseguridad diseñado bajo los mejores estándares de calidad para ser usado por pacientes y/o el personal médico. </p>
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="fw-normal">Polainas</h2> 
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card shadow-sm">
                <img src={FaceMask} className="bd-placeholder-img card-img-top" width="100%" height="225" alt="Tapabocas"/>
                <div className="card-body">
                  <p className="card-text paragraph">Un producto sanitario que cubre la boca y la nariz, proporcionando una barrera para reducir al mínimo la transmisión directa de agentes infecciosos.</p>
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="fw-normal">Tapaboca Quirúrgico</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card shadow-sm">
                <img src={Gasas} className="bd-placeholder-img card-img-top" width="100%" height="225" alt="Gasas"/>
                <div className="card-body">
                  <p className="card-text paragraph">La gasa tejida estéril es un dispositivo médico transitorio. Es un producto diseñado para limpiar las partes del cuerpo humano afectadas.</p>
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="fw-normal">Gasa Estéril</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Contentbox;