import { Parallax } from 'react-parallax';
import Contact from '../img/charanjeet-dhiman-mHusyBu4bxM-unsplash.jpg';

const Contacto = () => (
    <Parallax blur={5} className='image' bgImage={Contact} bgImageAlt="the cat" strength={500}>
        <div id="contacto" className='content'>
            <span className='img-txt'>Contactenos</span>
        </div>
    </Parallax>
);

export default Contacto;