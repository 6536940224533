import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import AlfaPic from "../img/alfa-safe.jpg";
import NiproPic from "../img/nipro.png";
import ClayPic from "../img/clay.jpg";
import ProtexPic from "../img/protex.png";
import CepilabPic from "../img/cepilab.png";



const Contentbox = () => {
    const options ={
        items:1,
        loop: true,
        autoplay: true,
        autoplayTimeout: 3000,
        animateOut:'slideOutUp',
        nav: false,
        dots: false,
        margin: 0,
        responsive: {
            1100: {
                items:3,
            },
            724: {
                items:1,
            },
            500: {
                items:1,
            },
            370: {
                items:1,
                innerWidth:"100%",
                innerHeight:"100%"
            },
        },
    };

    return(
      <div>
        <OwlCarousel className='owl-theme' {...options}>
            <div className='item'>
                <img src={AlfaPic} alt=""/>
            </div>
            <div className='item'>
                <img src={NiproPic} alt=""/>
            </div>
            <div className='item'>
                <img src={ClayPic} alt=""/>
            </div>
            <div className='item'>
               <img src={ProtexPic} alt=""/>
            </div>
            <div className='item'>
               <img src={CepilabPic} alt=""/>
            </div>
        </OwlCarousel>
    </div>
    )
}

export default Contentbox;