import React from 'react';
import Nidial from "../img/logo2.png"

const NavBar = () => {
    return(
    <div>
        <nav className="navbar navbar-expand-md fixed-top bg-light ">
            <div className="container-fluid">
              <a className="navbar-brand" href="#nosotros">
                <img src={Nidial} className="nidial-logo" alt="Nidial Medical Colombia" width="200" height="80"/>
              </a>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarCollapse">
                <ul className="navbar-nav me-auto mb-2 mb-md-0">
                  <li className="nav-item">
                    <a className="nav-link" id="nav-link" href="#nosotros">NOSOTROS</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" id="nav-link" href="#productos">PRODUCTOS</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" id="nav-link" href="#proveedores">PROVEEDORES</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" id="nav-link" href="#contacto">CONTACTENOS</a>
                  </li>
                </ul>
              </div>
            </div>
        </nav>
    </div>
    )
}

export default NavBar;