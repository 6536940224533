import { Parallax } from 'react-parallax';
import Provider from '../img/matheus-ferrero-yfmjALh1S6s-unsplash.jpg';

const Proveedores = () => (
    <Parallax blur={5} className='image' bgImage={Provider} bgImageAlt="the cat" strength={500}>
        <div id="proveedores" className='content'>
            <span className='img-txt'>Proveedores</span>
        </div>
    </Parallax>
);

export default Proveedores;