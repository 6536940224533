import { Parallax } from 'react-parallax';
import Us from '../img/national-cancer-institute-6NMcUDG37Yc-unsplash.jpg';

const Nosotros = () => (
    <Parallax blur={5} className='image' bgImage={Us} bgImageAlt="the cat" strength={500}>
        <div id="nosotros" className='content'>
            <span className='img-txt'>Nosotros</span>
        </div>
    </Parallax>
);

export default Nosotros;