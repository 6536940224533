import Nosotros from "./components/Nosotros";
import Productos from "./components/Productos";
import Proveedores from "./components/Proveedores";
import Contacto from "./components/Contacto";
import ContenidoContacto from "./components/ContenidoContacto";
import ContenidoUs from "./components/ContenidoUs";
import ContenidoProductos from "./components/ContenidoProductos";
import ContenidoProveedores from "./components/ContenidoProveedores";
import NavBar from "./components/Navbar";


function App() {
  return (
    <div>
      <NavBar/>
      <Nosotros/>
      <ContenidoUs/>
      <Productos/>
      <ContenidoProductos/>
      <Proveedores/>
      <ContenidoProveedores/>
      <Contacto/>
      <ContenidoContacto/>
    </div>
  );
}

export default App;
