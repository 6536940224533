import React from "react";
import MisionPic from "../img/Mision.jpg"
import VisionPic from "../img/Vision.jpg"
import ObjetivoPic from "../img/Objetivo.jpg"


const Contentbox = () => {
    return(
    <div className="txt-container">
        <div className="text-box" id="txt-nosotros">
            <h1 className="fw-normal">NOSOTROS</h1>
            <p className="paragraph">
            Somos una organización con presencia a nivel nacional,
            comprometida con la comercialización y distribución de
            insumos médicos y hospitalarios.
            </p>
            <p className="paragraph">
            Contamos con un equipo interdisciplinario de
            profesionales capacitados para brindar oportunamente
            calidad y soluciones efectivas a nuestros clientes.
            </p>
        </div>
        <div className="mi-vi-ob">
            <div className="row">
                <div className="col-lg-4">
                    <img src={MisionPic} className="bd-placeholder-img rounded-circle" width="140" height="140" alt="Imagen Mision"/>
                    <h2 className="fw-normal">Misión</h2>
                    <p className="paragraph">Contribuir en soluciones eficientes y eficaces mediante el suministro y la distribución de 
                    insumos médicos y hospitalarios; logrando un servicio óptimo y de responsabilidad social.</p>
                </div>
                <div className="col-lg-4">
                <img src={VisionPic} className="bd-placeholder-img rounded-circle" width="140" height="140" alt="Imagen Vision"/>
                  <h2 className="fw-normal">Visión</h2>
                  <p className="paragraph">Durante los próximos años, lograr ser precursores a nivel nacional en la prestación del servicio en suministro de  
                  insumos médicos y hospitalarios; consolidándonos en el mercado como una de las empresas que garantiza el crecimiento 
                  y éxito de sus clientes y colaboradores. </p>
                </div>
                <div className="col-lg-4">
                <img src={ObjetivoPic} className="bd-placeholder-img rounded-circle" width="140" height="140" alt="Imagen Objetivo"/>
                  <h2 className="fw-normal">Objetivo</h2>
                  <p className="paragraph">Comercializar y distribuir insumos médicos de calidad certificada, en las instituciones del área 
                  de la salud públicas y privadas en todo el territorio nacional; asegurando la calidad de sus productos y garantizando 
                  un excelente servicio postventa.</p>
                </div>
             </div>
        </div>
    </div>
    )
}

export default Contentbox;