import { Parallax } from 'react-parallax';
import Product from '../img/national-cancer-institute-cw2Zn2ZQ9YQ-unsplash.jpg';

const Productos = () => (
    <Parallax blur={5} className='image' bgImage={Product} bgImageAlt="the cat" strength={500}>
        <div id="productos" className='content'>
            <span className='img-txt'>Productos</span>
        </div>
    </Parallax>
);

export default Productos;